import firebase from 'firebase'

const config={
    apiKey: "AIzaSyDofC7d4hyUja1i3hl-svp9xvWge9VWeqA",
    authDomain: "gloreto-f63f5.firebaseapp.com",
    projectId: "gloreto-f63f5",
    storageBucket: "gloreto-f63f5.appspot.com",
    messagingSenderId: "431263010856",
    appId: "1:431263010856:web:4bc1cc23eef2976ad2e51d",
    measurementId: "G-Z9LBGS2MPQ",
}
firebase.initializeApp(config);
export default firebase