
const API_URL = 'https://gloreto-api.onrender.com';
const Hotel_ID ='60641fde66ceb2089b1bc468'

export {
    Hotel_ID,
    API_URL,
}

//{_partition:"project=6127126cae94bc64a5e4b23a"}
//62e763579f0908fc00b82722
// _id
// 6129b276821f9e41b4209c5a
// original
// thumbnail